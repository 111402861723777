import "./style.scss";
import { TextareaChangeEventDetail } from "@ionic/core";
import {
  IonButton,
  IonCol,
  IonFooter,
  IonGrid,
  IonIcon,
  IonItem,
  IonLabel,
  IonModal,
  IonRadio,
  IonRadioGroup,
  IonRow,
  IonSpinner,
  IonTextarea,
} from "@ionic/react";
import { api } from "@src/app/api";
import { CancelShiftPayload } from "@src/app/api/shift";
import { getOrWatchCurrentLocation } from "@src/app/common/location";
import { useGeolocationTrackingForShiftsEnabled } from "@src/app/hcpShifts/custom-hooks/useGeolocationTrackingForShiftsEnabled";
import { useDefinedEnv } from "@src/app/store/helperHooks/sessionHelpers";
import { logError } from "@src/appV2/lib/analytics";
import { USER_EVENTS } from "@src/constants";
import { Shift } from "@src/lib/interface";
import { closeCircle } from "ionicons/icons";
import moment from "moment-timezone";
import { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import { fetchAgentProfile } from "../../../dayView/api";
import { updateAgentProfileState } from "../../../onboardingStripe/actions";

export const HOURS_FROM_SHIFT_START_TO_INCLUDE_LOCATION = 4;
const ONE_DAY_IN_MILLIS = 1000 * 60 * 60 * 24;

const CancelShiftModal: React.VFC<{
  shift: Shift;
  isShiftLoading?: boolean;
  onDismiss?: () => any;
  onSuccess?: () => any;
  isPendingVerification?: boolean;
  onlyModal?: boolean;
}> = ({ shift, isShiftLoading, onDismiss, onSuccess, isPendingVerification, onlyModal }) => {
  const [showModal, setShowModal] = useState(false);
  const [reasonType, setReasonType] = useState<string>("");
  const [reasonDescription, setReasonDescription] = useState<string | null | undefined>("");
  const [isLoading, setLoading] = useState(false);
  const [agentProfileLoading, setAgentProfileLoading] = useState(false);
  const [showCancelButton, setShowCancelButton] = useState(true);
  const [areaActive, setAreaActive] = useState(false);

  const [isShiftEnded, setIsShiftEnded] = useState(false);

  const env = useDefinedEnv();

  const { callOffWindow, cancellationHoursMin, cancellationHoursMax } = env;
  const isShiftOngoing = moment().isAfter(shift.start);
  const history = useHistory();
  const dispatch = useDispatch();

  const geolocationTrackingForShiftsEnabled = useGeolocationTrackingForShiftsEnabled(shift);

  const reasonList = useMemo(() => {
    const shiftTimeDiff = moment(shift.start).diff(moment(), "hours");
    const hoursFromShiftStart = moment(shift.start).diff(moment(), "hours", true);
    const isCallOff = 0 < hoursFromShiftStart && hoursFromShiftStart <= callOffWindow;
    const isCancellation =
      hoursFromShiftStart > cancellationHoursMin && hoursFromShiftStart <= cancellationHoursMax;
    const isNoCallNoShow = hoursFromShiftStart < 0;
    const list: [string, string][] = [];

    if (isCallOff || isCancellation || hoursFromShiftStart >= 24) {
      list.push(
        ["NO_CAR_FOR_SHIFT", "Do not have a car to get to shift"],
        ["TESTED_COVID+", "Tested COVID+ or displaying symptoms"],
        ["SICK_OTHER_ILLNESS", "Sick - other illness"],
        ["FACILITY_ASKING_SWITCH_SHIFTS", "Facility has asked me to switch shifts"],
        ["FACILITY_COVID+_STATUS", "Facility’s COVID+ status was not updated"],
        ["FACILITY_CANCELLED_SHIFTS", "Facility cancelled too many shifts"]
      );
    }
    if (shiftTimeDiff === 1) {
      list.push(["SENT_HOME_BY_FACILITY", "Sent home by facility"]);
    }
    if (isNoCallNoShow) {
      list.push(
        ["CAR_BROKE_DOWN", "Car broke down on my way to the shift"],
        ["TESTED_COVID+_AT_FACILITY", "Tested COVID+ at the facility"],
        ["DISPLAYING_COVID_SYMPTOMS", "Displaying COVID symptoms during shift"],
        ["FEELING_ILL_DURING_SHIFT", "Started feeling ill during shift"],
        ["SENT_HOME_BY_FACILITY", "Sent home by facility"],
        ["FACILITY_ASKING_SWITCH_SHIFTS", "Facility has asked me to switch shifts"],
        ["FACILITY_COVID+_STATUS_NOT_UPDATED", "Facility’s COVID+ status was not updated"]
      );
    }
    list.push(["NONE", "None of the above"]);
    return list;
  }, [callOffWindow, cancellationHoursMax, cancellationHoursMin, shift.start]);

  useEffect(() => {
    if (!shift) {
      return;
    }
    const isShiftEndedDiff = moment(shift?.end)
      .add(env?.shiftEndGracePeriod, "minutes")
      .diff(moment());
    setIsShiftEnded(isShiftEndedDiff <= 0);

    if (isShiftEndedDiff > ONE_DAY_IN_MILLIS) {
      return;
    }

    const timeout = setTimeout(() => {
      setIsShiftEnded(true);
    }, isShiftEndedDiff);
    return () => clearTimeout(timeout);
  }, [env, shift]);

  useEffect(() => {
    async function handleUserUpdate() {
      setAgentProfileLoading(true);
      const updatedAgent = await fetchAgentProfile();
      dispatch(
        updateAgentProfileState({
          stage: updatedAgent?.stage,
        })
      );
      setAgentProfileLoading(false);
    }
    handleUserUpdate();
  }, [dispatch, shift]);

  useEffect(() => {
    setReasonType("");
    setReasonDescription("");
  }, [showModal]);

  useEffect(() => {
    if (isShiftEnded || shift.clockInOut?.end) {
      setShowCancelButton(false);
    }
  }, [reasonDescription, isShiftEnded, shift, isShiftOngoing]);

  const selectedReason = (e) => {
    setReasonType(e.detail.value);
  };

  const [buttonIsDisabled, buttonContent] = useMemo(() => {
    if (!reasonType) {
      return [true, "Please select a reason"];
    }

    if (!reasonDescription) {
      return [true, "Add description to cancel"];
    }

    return [isLoading, "Cancel shift"];
  }, [reasonType, reasonDescription, isLoading]);

  // Return base claim endpoint url

  const onCancelShift = async () => {
    if (isPendingVerification) {
      return onCancelUnverifiedShift();
    }

    setLoading(true);

    const payload: CancelShiftPayload = {
      shiftId: shift._id as string,
      reasonType,
      reasonDescription: reasonDescription as string,
      clientCancellationTime: new Date().toISOString(),
    };

    const hoursFromShiftStart = moment(shift.start).diff(moment(), "hours", true);

    if (
      hoursFromShiftStart <= HOURS_FROM_SHIFT_START_TO_INCLUDE_LOCATION &&
      geolocationTrackingForShiftsEnabled
    ) {
      const { location, error: positionError } = await getOrWatchCurrentLocation();

      if (!positionError) {
        payload.coordinates = location;
      }
    }

    try {
      await api.shift.selfCancelShift(payload);
    } catch (error) {
      logError(USER_EVENTS.SELF_CANCEL_SHIFT_ERROR, error);
      /*
        FIXME
          This API call was changed to throw errors instead of resolving them.
          Add error handling for this functionality
      */
    }

    setShowModal(false);
    setLoading(false);
    // setIsCancelled(true)
    setShowCancelButton(true);
    setReasonDescription("");
    history.push("/home/myShifts");
  };

  const onCancelUnverifiedShift = async () => {
    setLoading(true);
    setShowModal(false);

    try {
      await api.shift.selfCancelShift({
        shiftId: shift._id as string,
        reasonType,
        reasonDescription: reasonDescription as string,
        clientCancellationTime: new Date().toISOString(),
      });
    } catch (error) {
      logError(USER_EVENTS.SELF_CANCEL_SHIFT_ERROR, error);
      /*
        FIXME
          This API call was changed to throw errors instead of resolving them.
          Add error handling for this functionality
      */
    }

    setLoading(false);
    setShowCancelButton(true);
    onSuccess?.();

    history.push("/home/myShifts");
  };

  const elementRef = useRef<HTMLIonTextareaElement>(null);
  useEffect(() => {
    if (reasonDescription || !elementRef?.current) {
      return;
    }

    // Auto Grow text area
    const textArea = elementRef.current.querySelector("textarea");
    if (!textArea) {
      return;
    }

    requestAnimationFrame(() => {
      textArea.style.height = "inherit";
      textArea.style.height = `${textArea.scrollHeight}px`;
    });
  }, [reasonType, reasonDescription]);

  const modal = (
    <IonModal
      cssClass="cancelModal"
      isOpen={showModal || !!onlyModal}
      swipeToClose
      backdropDismiss
      onDidDismiss={() => {
        setShowModal(false);
        onDismiss && onDismiss();
      }}
    >
      <IonGrid class="ion-no-margin">
        <IonRow>
          <IonCol>
            <b style={{ fontSize: "18px" }} className="modal-reason-title">
              Select a cancellation reason
            </b>
          </IonCol>
          <IonCol class="ion-float-right" size="1" style={{ marginLeft: "auto" }}>
            <IonIcon
              onClick={() => {
                setShowModal(false);
                onDismiss && onDismiss();
              }}
              className="cancel-modal-close-icon ion-float-right"
              icon={closeCircle}
              style={{ cursor: "pointer" }}
            />
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol>
            <IonRadioGroup
              value={reasonType}
              onIonChange={(e) => selectedReason(e)}
              data-testid="cancel-shift-reason-type"
            >
              {reasonList.map(([value, text]) => (
                <IonItem className="modal-items" key={value} lines="none">
                  <IonLabel>{text}</IonLabel>
                  <IonRadio mode="md" className="modal-radio" slot="start" value={value} />
                </IonItem>
              ))}
            </IonRadioGroup>
            <div style={{ visibility: reasonType ? "visible" : "hidden" }}>
              <h4 className="tell-us">Tell us what happened</h4>
              <IonTextarea
                ref={elementRef}
                onIonFocus={() => setAreaActive(true)}
                onIonBlur={() => setAreaActive(false)}
                className={`reason-description ${areaActive ? "active" : ""}`}
                placeholder="Add a description here (required)"
                data-testid="cancel-shift-reason-textarea"
                onIonChange={(e: CustomEvent<TextareaChangeEventDetail>) =>
                  setReasonDescription(e.detail.value)
                }
                value={reasonDescription}
              />
            </div>
          </IonCol>
        </IonRow>
      </IonGrid>
      <div>
        <IonButton
          data-testid="cancel-shift-button"
          size="large"
          expand="block"
          shape="round"
          color="primary"
          disabled={buttonIsDisabled}
          onClick={onCancelShift}
          className={showModal ? "shift-cancel-button" : "cancel-button"}
        >
          {isLoading ? (
            <IonSpinner name="lines" data-testid="cancel-shift-button-loading" />
          ) : (
            buttonContent
          )}
        </IonButton>
      </div>
    </IonModal>
  );

  if (onlyModal) {
    return modal;
  }

  if (isShiftLoading || agentProfileLoading) {
    return (
      <IonFooter className="ion-no-border ion-text-center">
        {" "}
        <IonSpinner style={{ margin: "0 auto" }} name="lines" />{" "}
      </IonFooter>
    );
  }

  return (
    <>
      <IonFooter className="ion-no-border ion-text-center" style={{ paddingBottom: 15 }}>
        {showCancelButton && (
          <>
            <IonButton
              data-testid="open-cancel-shift-modal-button"
              onClick={() => setShowModal(true)}
              shape="round"
              size="large"
              expand="block"
              color="gray"
            >
              Cancel this shift
            </IonButton>
          </>
        )}
      </IonFooter>
      {modal}
    </>
  );
};

export { CancelShiftModal };
